import { VOLUME_TIMEFRAME, VOLUME_UNIT } from "../constants";

export interface ITapsInfo {
  config: {
    cost?: number;
    soldPerYear?: number;
    priceIndex?: number;
    newCost?: boolean;
    glass?: IGlass;
  };
  beverage: IBeverage | null;
}

export interface IBeverage extends IBeverageInfo {
  id?: string;
  type: EBeverageType;
  priceIndex: number;
  pricePerHl: number;
  pricePerKeg: number;
  litersPerKeg: number;
}

export interface IBeverageInfo {
  id?: string;
  name: string;
  description: string;
  category: EBeverageCategory;
  origin: string;
  abv: number;
  ibu: number;
  speciality: boolean;
  lense: string;
  mainColor: string;
}

export interface IGlass {
  glassName: string;
  glassSize: number;
}

export interface ICountryConfiguration {
  name: string;
  unit: VOLUME_UNIT;
  defaultTimeframe: VOLUME_TIMEFRAME;
  currency: string;
  defaultSellInUnit: VOLUME_UNIT;
  glasses: IGlass[];
  defaultGlass: IGlass;
  co2Costs: {
    small: number;
    medium: number;
    large: number;
    fixed?: number;
  };
  wastage: {
    wasteFromBeerChanging: number;
    wasteFromChangingLabour: number;
    beerRemainingInsideKeg: number;
    wasteFromCleaningSteel: number;
    wasteFromCleaningDm: number;
    dmCleaningHourlyRate: number;
    dmCleansPerYear: number;
    simplifiedWasteSteel: number;
    simplifiedWasteDm: number;
    dmRentalCost: number[] | undefined;
    steelRentalCost: number[] | undefined;
    steelExternalCleaning: number;
    dmExternalCleaning: number;
  };
  marketType: EMarketType;
  useTax: boolean;
  tax: number;
  useSellInPrice: boolean;
  defaultCompetitorPrice: number;
  defaultCompetitorPremiumPrice: number;
  tapRecommendations: ITapRecommendation[];
  DMUplift: number;
  usePremium: boolean;
  shareReport: boolean;
  shareReportDays: number;
  downloadReport: boolean;
}

export enum EMarketType {
  direct = "direct",
  indirect = "indirect",
  both = "both"
}

export enum EBeverageType {
  STEEL = "Steel",
  DM = "Draughtmaster",
  WATER = "Water"
}

export enum EBeverageCategory {
  PREMIUM = "Premium",
  WORLD = "World",
  CRAFT = "Craft",
  STANDARD = "Standard",
  Seasonal = "Seasonal",
  LOCAL = "Local",
  OTHER = "Other"
}

export interface ICo2Info {
  cost?: number;
}

export interface ITapRecommendation {
  volumeRequired: number;
  numberOfTaps: number;
}

export interface ICountry {
  id: string;
  name: string;
  flag: string;
  beverages: IBeverage[];
  configuration: ICountryConfiguration;
}

import {DEFAULT_LANG, VOLUME_TIMEFRAME, VOLUME_UNIT} from "../../constants";
import {translate, translateBoolean, translateNumber, translateString} from "./translate";
import {EMarketType, ICountryConfiguration} from "../types";

const VOLUME_UPLIFT = 10;

export default function mapConfiguration(config: any, lang = DEFAULT_LANG): ICountryConfiguration {
  const co2Costs = translate(config.fields.co2costs);
  const wastage = translate(config.fields.wastage);
  const tapRecommendations = translate(config.fields.tapRecommendations);
  const glasses = translate(config.fields.glasses);
  const defaultGlass = translate(config.fields.defaultGlass);

  return {
    name: translateString(config.fields.name, lang),
    unit: translateString(config.fields.unit, lang) as VOLUME_UNIT,
    defaultTimeframe: translateString(config.fields.defaultTimeframe, lang) as VOLUME_TIMEFRAME,
    currency: translateString(config.fields.currency, lang),
    defaultSellInUnit: translateString(config.fields.defaultSellInUnit, lang) as VOLUME_UNIT,
    glasses: glasses?.map((g: any) => ({
      glassSize: translateNumber(g.fields.glassSize, lang),
      glassName: translateString(g.fields.glassName, lang)
    })),
    defaultGlass: {
      glassSize: translateNumber(defaultGlass.fields.glassSize, lang),
      glassName: translateString(defaultGlass.fields.glassName, lang)
    },
    co2Costs: {
      small: translateNumber(co2Costs.fields.small),
      medium: translateNumber(co2Costs.fields.medium),
      large: translateNumber(co2Costs.fields.large),
      fixed: translateNumber(co2Costs.fields.fixed)
    },
    wastage: {
      wasteFromBeerChanging: translateNumber(wastage.fields.wasteFromBeerChanging),
      wasteFromChangingLabour: translateNumber(wastage.fields.wasteFromChangingLabour),
      wasteFromCleaningDm: translateNumber(wastage.fields.wasteFromCleaningDm),
      wasteFromCleaningSteel: translateNumber(wastage.fields.wasteFromCleaningSteel),
      beerRemainingInsideKeg: translateNumber(wastage.fields.beerRemainingInsideKeg),
      dmCleaningHourlyRate: translateNumber(wastage.fields.dmCleaningHourlyRate),
      dmCleansPerYear: translateNumber(wastage.fields.dmCleansPerYear),
      simplifiedWasteSteel: translateNumber(wastage.fields.simplifiedWasteSteel),
      simplifiedWasteDm: translateNumber(wastage.fields.simplifiedWasteDm),
      dmRentalCost: translateString(wastage.fields.dmRentalCost) !== "" ? translateString(wastage.fields.dmRentalCost).split("|").map(n => parseFloat(n)) : undefined,
      steelRentalCost: translateString(wastage.fields.steelRentalCost) !== "" ? translateString(wastage.fields.steelRentalCost).split("|").map(n => parseFloat(n)) : undefined,
      steelExternalCleaning: translateNumber(wastage.fields.steelExternalCleaning),
      dmExternalCleaning: translateNumber(wastage.fields.dmExternalCleaning)
    },
    useTax: translateBoolean(config.fields.useTax),
    tax: translateNumber(config.fields.tax),
    useSellInPrice: translateBoolean(config.fields.useSellInPrice),
    defaultCompetitorPrice: translateNumber(config.fields.defaultCompetitorPrice),
    defaultCompetitorPremiumPrice: translateNumber(config.fields.defaultCompetitorPremiumPrice),
    marketType: translateString(config.fields.marketType) as EMarketType,
    tapRecommendations: tapRecommendations?.map((t: any) => ({
      volumeRequired: translateNumber(t.fields.volumeRequired),
      numberOfTaps: translateNumber(t.fields.numberOfTaps)
    })),
    DMUplift: translateNumber(config.fields.dmUplift) || VOLUME_UPLIFT,
    usePremium: translateBoolean(config.fields.usePremium),
    shareReport: translateBoolean(config.fields.shareReport),
    shareReportDays: translateNumber(config.fields.shareReportDays),
    downloadReport: translateBoolean(config.fields.downloadReport)
  };
}

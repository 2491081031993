import {SyncCollection} from "contentful";
import {IContentfulContent, IEntries, ILanguageAvailable, ILanguageContent} from "./types";
import {translate} from "./translate";
import mapLanguageInfo from "./mapLanguageInfo";
import mapPage from "./mapPage";
import mapCountries from "./mapCountries";
// eslint-disable-next-line import/extensions
import italianTranslation from "../../assets/italian-translation.json";
// eslint-disable-next-line import/extensions
import finnishTranslation from "../../assets/finnish-translation.json";
// eslint-disable-next-line import/extensions
import germanTranslation from "../../assets/german-translation.json";

export const translations: any[] = [{
    code: "it-IT",
    entries: italianTranslation
},{
    code: "fi-FI",
    entries: finnishTranslation
},{
    code: "de-DE",
    entries: germanTranslation
}];

function mapLanguage(entries: IEntries, lang: string): ILanguageContent {
    return {
        ...mapLanguageInfo(entries, lang),
        ...mapPage(entries, lang),
        ...mapCountries(entries, lang)
    };
}


export function mapContentfulEntries(
    contentfulEntries: SyncCollection["entries"]
): IContentfulContent {
    const entries: IEntries = {};
    if (contentfulEntries) {
        contentfulEntries.forEach(entry => {
            let target = entries[entry.sys.contentType.sys.id];

            translations.forEach(translation => {
                const translationEntry = translation.entries[entry.sys.id];
                if (translationEntry) {
                    Object.keys(entry.fields).forEach(f => {
                        if (translationEntry[f]) {
                            entry.fields[f][translation.code] = translationEntry[f][translation.code];
                        }
                    });
                } else if(entry.fields.stringKey) {
                    console.log(translation.code,"NOT FOUND", entry);
                }
            });

            if (typeof target === "undefined") {
                entries[entry.sys.contentType.sys.id] = {
                    ordered: [],
                    keyed: {}
                };

                target = entries[entry.sys.contentType.sys.id];
            }

            target.ordered.push(entry);
            target.keyed[entry.sys.id] = entry;
        });
    }
    const languagesAvailable: ILanguageAvailable = {};
    entries.languageInfo.ordered.forEach(entry => {
        const code = translate(entry.fields.languageCode);
        if (code) {
            languagesAvailable[code] = mapLanguage(entries, code);
        }
    });

    return {
        ...languagesAvailable
    };
}
